import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

class ThanksPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Thanks" />
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1 content">
                <h2 className="title is-size-2 has-text-centered has-text-weight-bold is-bold-light">
                  Thanks
                </h2>
                <p className="has-text-centered">
                  Thank you for getting in touch, we have received your message
                  and will contact you shortly.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ThanksPage
